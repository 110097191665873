import React, { useState, useEffect, createRef } from "react";
import NavBarToggleBtn from "../../atoms/nav-bar-toggle-btn";
import { getUserData } from "../../utils/getUserData";
import WhitelistedLogin from "../navbar-whitelisted";
import useStorage from "../../../hook/useStorage";
import { Link } from "@quintype/components";
import { Search } from "../navbar-search";
import Logout from "../navbar-logout";
import Login from "../navbar-login";
import "./menu-bar-ilkka.m.css";

const MenuBarIlkka = ({ mainNav, menuItems, hamburgerMenu, pageType, currentPath }) => {
  const [firstParentId, setFirstParentId] = useState("");
  const [secondParentId, setSecondParentId] = useState("");
  const [goback, setGoback] = useState(true);
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false);
  const [index, setIndex] = useState("");
  const container = React.createRef();
  const [checkUser, activeProducts] = useStorage();
  const [isTrue, setIsTrue] = useState(false);
  const toggle = () => setIsTrue(!isTrue);
  const [wListed, setWlisted] = useState(false);
  /*getting first letters from username to be shown for logged user*/
  let username = "";
  let usernameSplitted = "";
  let usernameFirstletters = "";
  const usrname = getUserData();
  if (typeof window !== 'undefined') {
    var usernam = usrname.username;
    if (usernam == null || usernam == undefined || username == null) {
      username = undefined;
    } else {
      username = usernam;
      usernameSplitted = username && username.split(/\s+/);
    }
    if (username == "undefined") {
      usernameFirstletters = ""
    } else {
      usernameFirstletters = usernameSplitted && usernameSplitted.map(function (name) {
        return name[0];
      }).join('');
    }
  }
  const showChild = (id, key) => {
    if (id === firstParentId) {
      setFirstParentId(id);
      setSecondParentId(null);
      setIsMegaMenuOpen(!isMegaMenuOpen);
      setIndex(key);
      setGoback(true);
    } else {
      setFirstParentId(id);
      setSecondParentId(null);
      setIsMegaMenuOpen(true);
      setIndex(key);
      setGoback(true);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    if (typeof window === 'undefined') {
      return;
    } else {
      const listed = JSON.parse(window.localStorage.getItem("ipUser"));
      setWlisted(listed)
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [])
  const handleClickOutside = event => {
    if (container.current && !container.current.contains(event.target)) {
      setIsMegaMenuOpen(false);
      setIndex("");
    }
  };
  const showSecondChild = id => {
    setSecondParentId(id);
    setIsMegaMenuOpen(true);
    setGoback(!goback);
  };
  const onMenuToggle = id => setIndex(id);
  const showChildMenu = child => {
    return (
      child.length >= 1 &&
      isMegaMenuOpen === true && (
        <div className="dropdown-menu" styleName="dropdown-list">
          <ul styleName="dropdown">
            {child.map(item => {
              return item.title && item.children.length < 1 ? (
                <li key={item.title} styleName="no-children">
                  {item.isExternalLink && !(
                                item.completeUrl.includes("ilkkapohjalainen.fi") ||
                                item.completeUrl.includes("localhost") ||
                                item.completeUrl.includes("qtstage")
                              ) ? (
                    <a styleName="menu-item-link" target="_blank" rel="noopener noreferrer" href={item.url || "/"}>
                      {item.title}
                    </a>
                  ) : (
                    <Link styleName="menu-item-link" href={item.completeUrl || "/"}>
                      {item.title}
                    </Link>
                  )}
                  <hr styleName="parent-divider" />
                </li>
              ) : (
                <li key={item.title} styleName="parent-link">
                  <a styleName="menu-item-link-parent" onClick={() => showSecondChild(item.id)}>
                    {item.title} {item.children.length >= 1 && <i className="ri-arrow-down-s-line"></i>}
                  </a>
                  <hr styleName="parent-divider" />
                  {secondParentId === item.id ? showSecondChildMenu(item.children) : null}
                  <ul>
                    {item.children.map(item => {
                      return (
                        item.title && (
                          <li
                            styleName={`second-child-menu-desktop ${item.title === "empty row" ? "empty-row" : ""}`}
                            key={item.title}
                          >
                            {(item.isExternalLink &&
                              !(
                                item.completeUrl.includes("ilkkapohjalainen.fi") ||
                                item.completeUrl.includes("localhost") ||
                                item.completeUrl.includes("qtstage")
                              )) ||
                              item.completeUrl.includes("lounaspaikka") === true ? (
                              <a
                                styleName="menu-item-link second-child-menu"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={item.completeUrl || "/"}
                              >
                                {item.title}
                              </a>
                            ) : (
                              <Link styleName="menu-item-link second-child-menu" href={item.completeUrl || "/"}>
                                {item.title}
                              </Link>
                            )}
                          </li>
                        )
                      );
                    })}
                  </ul>
                </li>
              );
            })}
            <li styleName="email-item-link">
              <a href="https://asiakaspalvelu.i-mediat.fi/ilkkapohjalainen/uutiskirje" target="_blank" rel="noreferrer">
                <i className="ri-mail-line"></i> Tilaa uutiskirje
              </a>
            </li>
            <hr styleName="parent-divider" />
          </ul>
        </div>
      )
    );
  };

  const showSecondChildMenu = child => {
    return secondParentId !== null && child.length >= 1 ? (
      <ul styleName={`${goback === true ? "hide-menu" : ""} `}>
        {child.map(item => {
          return (
            item.title && (
              <li
                styleName={`second-child-menu-mobile ${item.title === "empty row" ? "empty-row" : ""}`}
                key={item.title}
              >
                {item.isExternalLink && !(
                                item.completeUrl.includes("ilkkapohjalainen.fi") ||
                                item.completeUrl.includes("localhost") ||
                                item.completeUrl.includes("qtstage")
                              ) ? (
                  <a
                    styleName="menu-item-link second-child-menu"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item.completeUrl || "/"}
                  >
                    {item.title}
                  </a>
                ) : (
                  <Link styleName="menu-item-link second-child-menu" href={item.completeUrl || "/"}>
                    {item.title}
                  </Link>
                )}
              </li>
            )
          );
        })}
        <hr styleName="parent-divider" />
      </ul>
    ) : null;
  };

  const currentSection = pageType === "section-page" ? currentPath.split("/")[1] : "";
  return (
    <div styleName="wrapper">
      <div styleName="inner-wrapper">
        <div styleName="menu-group">
          <nav styleName="nav">
            <ul styleName="menu-list menu-list-right" ref={container}>
              <li styleName="menu-list-item search">
                <Search />
              </li>
              <li styleName="menu-list-item menu-digilehti digilehti-link">
                <a href="/nakoislehti"><span styleName="hidden-text">Näköislehti</span> <i className="ri-newspaper-line"></i></a>
              </li>
              <li styleName="menu-list-item menu-login">
                {(checkUser && !wListed) &&
                  <>
                    <span onClick={() => toggle()} styleName="user-link"><i className="ri-user-line" ></i><span styleName="omat-tiedot">{usernameFirstletters}</span><i className="ri-arrow-down-s-line"></i></span>
                    {isTrue && <ul styleName="login-info">
                      <Link href="/omat-tietoni">
                        <li styleName="menu-list-item-login-info"><i className="ri-user-line"></i>Omat tietoni</li>
                      </Link>
                      <Link href="/tallentamani-artikkelit">
                        <li styleName="menu-list-item-login-info"><i className="ri-bookmark-line"></i>Tallentamani artikkelit</li>
                      </Link>
                      <Link href="/seuraamani-aiheet">
                        <li styleName="menu-list-item-login-info"><i className="ri-check-line"></i>Seuraamani aiheet</li>
                      </Link>
                      <Link href="/tulo/logout">
                        <li styleName="menu-list-item-login-info"><i className="ri-logout-box-line"></i>Kirjaudu ulos</li>
                      </Link>
                    </ul>}
                  </>}


                  {(checkUser && !wListed) && <Logout />}
        {(!checkUser && !wListed) && <Login />}
        {(wListed) && <WhitelistedLogin />}

              </li>
              {(!activeProducts) && <li styleName="menu-list-item menu-tilaa">
                <a href="https://asiakaspalvelu.i-mediat.fi/ilkkapohjalainen/tilaa" target="_blank" styleName="subscribe-link">Tilaa</a>
              </li>}
              {menuItems.map((menuItems, index) => {
                const getHomePage = pageType === "Hamburger" && menuItems.title === "Talous" ? "active" : "";
                return (
                  menuItems.title && (
                    <React.Fragment>
                      {menuItems.title === "Hamburger" ? (
                        <li styleName={`menu-list-item`} key={menuItems.id}>
                          <div styleName="hamburger-link" onClick={() => showChild(menuItems.id, index)}>
                            <span styleName="valikkotext">Valikko</span>
                            <NavBarToggleBtn
                              onMenuToggle={() => onMenuToggle(index)}
                              isMegaMenuOpen={isMegaMenuOpen}
                              id={index}
                            />
                          </div>
                          {firstParentId === menuItems.id ? showChildMenu(hamburgerMenu) : null}
                        </li>
                      ) : menuItems.children.length < 1 ? (
                        <li
                          styleName={`menu-list-item ${getHomePage} ${currentSection === menuItems["section-slug"] ? "active" : ""
                            }`}
                          key={menuItems.id}
                        >
                          {menuItems.isExternalLink ? (
                            <a
                              styleName="menu-item-link"
                              target="_blank"
                              rel="noopener noreferrer"
                              href={menuItems.completeUrl || "/"}
                            >
                              {menuItems.title}
                            </a>
                          ) : (
                            <Link styleName="menu-item-link" href={menuItems.completeUrl || "/"}>
                              {menuItems.title}
                            </Link>
                          )}
                        </li>
                      ) : (
                        <li
                          styleName={`menu-list-item ${getHomePage} ${currentSection === menuItems["section-slug"] ? "active" : ""
                            }`}
                          key={menuItems.id}
                        >
                          <a styleName="menu-item-link" onClick={() => showChild(menuItems.id, index)}>
                            {menuItems.title}
                            {menuItems.children.length >= 1 && (
                              <img styleName="arrowDown" src="/assetify/arrow-down.svg" />
                            )}
                          </a>
                          {firstParentId === menuItems.id ? showChildMenu(menuItems.children) : null}
                        </li>
                      )}
                    </React.Fragment>
                  )
                );
              })}
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default MenuBarIlkka;
